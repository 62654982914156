import React from 'react';
import * as Ant from 'antd';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';
import Theme from '../../Theme';

export default function Morinaga({record, setRecord}) {
  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          會員建立時間
        </Ant.Col>
        <Ant.Col
          span={6}
          style={{flexDirection: 'column', alignItems: 'flex-start'}}>
          <AdminDateTimeWidget
            value={record.member_since}
            onChange={(value) => {
              if (record.start_time < value) {
                Ant.message.error('會員建立時間必須早於活動開始時間');
                return;
              }

              setRecord((prev) => ({
                ...prev,
                member_since: value,
              }));
            }}
          />
          <div style={{color: '#aaa'}}>此時間為基準之新會員，符合資格</div>
        </Ant.Col>

        <Ant.Col span={2} className="label">
          贈送次數
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.giving_qty}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              setRecord((prev) => ({...prev, giving_qty: value}));
            }}
            type="number"
          />
        </Ant.Col>

        <Ant.Col span={2} className="label">
          每次贈送區間(天)
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.period}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              setRecord((prev) => ({...prev, period: value}));
            }}
            type="number"
          />
        </Ant.Col>
      </Ant.Row>
    </React.Fragment>
  );
}
